import React from 'react';
import { useEnvironment, useTranslation } from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { classes, st } from './EmptyState.st.css';

export default ({ dataHook = 'empty-state' }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={dataHook}
      role="status"
    >
      <Text
        data-hook="empty-state-title"
        className={classes.title}
        tagName="h2"
        typography={TYPOGRAPHY.largeTitle}
      >
        {t('empty-state.title')}
      </Text>
      <Text data-hook="empty-state-text" className={classes.text}>
        {t('empty-state.text')}
      </Text>
    </div>
  );
};
