import React from 'react';
import {
  useTranslation,
  useEnvironment,
  useExperiments,
} from 'yoshi-flow-editor-runtime';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import { st, classes } from './BookButton.st.css';
import { useRenderLocation } from '../useRenderLocation';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useWidgetEvents } from '../useWidgetEvents';
import settingsParams from '../../settingsParams';
import { useVisitorBILogger } from '../../bi/biContext';
import { useBookButtonViewModel } from '../useBookButtonViewModel';

interface ControllerProps {
  dataHook?: string;
  className?: string;
  renderedAt?: string;
}

type BookButtonProps = ControllerProps;

const BookButtonComponent: React.FC<BookButtonProps> = ({
  dataHook,
  className,
  renderedAt,
}) => {
  const { t } = useTranslation();
  const location = useRenderLocation();
  const settings = useSettings();
  const { navigateToCalendar } = useWidgetEvents();
  const { isMobile } = useEnvironment();
  const biLogger = useVisitorBILogger();
  const getText = (settingsKey, defaultKey) =>
    settings.get(settingsKey) || t(defaultKey);
  const bookButtonViewModel = useBookButtonViewModel();
  const { experiments } = useExperiments();
  const buttonText =
    experiments.enabled('specs.bookings.NewServicePagePendingApproval') &&
    bookButtonViewModel?.isPendingApprovalFlow
      ? getText(
          settingsParams.pendingApprovalButtonText,
          'app.widget.cta.pending-approval',
        )
      : getText(settingsParams.bookButtonText, 'app.widget.cta.book-now');

  return (
    <Button
      className={st(classes.root, { location, isMobile }, className)}
      size={SIZE.medium}
      data-hook={dataHook || 'book-button'}
      priority={PRIORITY.primary}
      onClick={() => {
        biLogger.bookingsBookItClick({
          section: renderedAt,
        });
        navigateToCalendar(location);
      }}
      role="link"
      fullWidth={settings.get(settingsParams.buttonsFullWidth)}
      upgrade
    >
      {buttonText}
    </Button>
  );
};

export default BookButtonComponent;
