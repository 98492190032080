import React from 'react';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { PolicySectionViewModel } from '../../../../service-page-view-model/policy-section-view-model/policySectionViewModel';
import { SectionTypes } from '../../types';
import settingsParams from '../../settingsParams';
import Section from '../Section';
import SectionTitle from '../SectionTitle/SectionTitle';
import SectionText from '../SectionText/SectionText';
import { useTranslation } from 'yoshi-flow-editor-runtime';

export type PolicyProps = {
  viewModel: PolicySectionViewModel;
  className?: string;
};

const PolicyComponent = ({ viewModel, className }: PolicyProps) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const policySectionTitle = () => (
    <SectionTitle
      title={
        settings.get(settingsParams.policySectionTitle) ||
        t('app.settings.sections.policy.default.title')
      }
      dataHook="policy-title"
      className={className}
    />
  );

  const hasCancellationPolicy = !!viewModel.cancellationPolicy;

  const cancellationPolicy = () => (
    <SectionText
      text={viewModel?.cancellationPolicy}
      dataHook="cancellation-policy"
      className={className}
    />
  );

  return hasCancellationPolicy ? (
    <Section sectionType={SectionTypes.POLICY} data-hook="policy-section">
      <div>
        {policySectionTitle()}
        {cancellationPolicy()}
      </div>
    </Section>
  ) : null;
};

export default PolicyComponent;
