import React from 'react';

export const CurrentLocationContext = React.createContext<string | undefined>(
  undefined,
);

export const CurrentLocationConsumer = CurrentLocationContext.Consumer;
export const CurrentLocationProvider = CurrentLocationContext.Provider;

export const useCurrentLocation = () =>
  React.useContext(CurrentLocationContext);
