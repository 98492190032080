
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/e47ef567209736c/packages/bookings-service-details-widget/src/components/BookingServicePage/Widget/index.tsx';
  import {
    createHttpClientBridge, HttpProvider,
  } from 'yoshi-flow-editor-runtime';

  import stylesParams from '/home/builduser/work/e47ef567209736c/packages/bookings-service-details-widget/src/components/BookingServicePage/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://dd0a55ccb8124b9c9d938e3acf41f8aa@sentry.wixpress.com/514',
      id: 'dd0a55ccb8124b9c9d938e3acf41f8aa',
      projectName: 'bookings-service-details-wi-wu',
      teamName: 'bookings',
    };

  var UserComponent = WidgetWrapper({
      createHttpClientBridge, HttpProvider,
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'BookingServicePage'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
